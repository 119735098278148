
import { createRouter, createWebHashHistory } from 'vue-router';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	
		{
			path: '/', 
			alias: '/home',
			name: 'home' , 
			component: () => import('./pages/home/home.vue'),
			props: true
		},
		//Dashboard routes


//categories routes
			{
				path: '/categories/:fieldName?/:fieldValue?',
				name: 'categorieslist',
				component: () => import('./pages/categories/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/categories/view/:id', 
				name: 'categoriesview', 
				component: () => import('./pages/categories/view.vue'), 
				props: true
			},
		

//posts routes
			{
				path: '/posts/:fieldName?/:fieldValue?',
				name: 'postslist',
				component: () => import('./pages/posts/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/posts/view/:id', 
				name: 'postsview', 
				component: () => import('./pages/posts/view.vue'), 
				props: true
			},
		
			{
				path: '/posts/related_posts/:fieldName?/:fieldValue?',
				name: 'postsrelated_posts',
				component: () => import('./pages/posts/related_posts.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/posts/readmore/:fieldName?/:fieldValue?',
				name: 'postsreadmore',
				component: () => import('./pages/posts/readmore.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/posts/latest/:fieldName?/:fieldValue?',
				name: 'postslatest',
				component: () => import('./pages/posts/latest.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/posts/older/:fieldName?/:fieldValue?',
				name: 'postsolder',
				component: () => import('./pages/posts/older.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/posts/random/:fieldName?/:fieldValue?',
				name: 'postsrandom',
				component: () => import('./pages/posts/random.vue'), 
				props: route => passRouteToProps(route)
			},
	

//signature routes
			{
				path: '/signature/:fieldName?/:fieldValue?',
				name: 'signaturelist',
				component: () => import('./pages/signature/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/signature/view/:id', 
				name: 'signatureview', 
				component: () => import('./pages/signature/view.vue'), 
				props: true
			},
		
			{ 
				path: '/signature/add', 
				name: 'signatureadd', 
				component: () => import('./pages/signature/add.vue'), 
				props: true
			},
	
			{ 
				path: '/signature/edit/:id', 
				name: 'signatureedit', 
				component: () => import('./pages/signature/edit.vue'), 
				props: true
			},
		

//user routes
			{
				path: '/user/:fieldName?/:fieldValue?',
				name: 'userlist',
				component: () => import('./pages/user/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

	
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
	
	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	return router;
}
